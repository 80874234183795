<template>
  <el-dialog title="新增售卖" center width="800px" :visible.sync="value" :before-close="onClose">
    <div class="_sales-wrapper">
      <div class="_sales-item">
        <div class="_sales-header">
          <div class="_sales-title">
            <span>新增售卖</span>
            <el-tooltip
              effect="dark"
              content="同一个用户同时只能存在一个生效中的套餐"
              placement="top-start"
            >
              <span class="_tips el-icon-warning-outline"></span>
            </el-tooltip>
          </div>
        </div>
        <div class="_sales-content">
          <el-form ref="elForm" label-position="top" :model="params">
            <div class="_form-item-wrapper">
              <div class="_label-phone">
                <span class="_required">*</span>
                <span>手机号码</span>
              </div>
              <el-row :gutter="20">
                <el-col v-for="(item, idx) in params.phone" :key="idx" :span="6" class="_col-item">
                  <el-form-item :prop="`phone[${idx}].name`" :rules="rules">
                    <div class="_phone-wrapper">
                      <el-input
                        v-model="params.phone[idx].name"
                        placeholder="请输入手机号"
                        maxlength="11"
                        clearable
                      />
                      <span
                        v-show="
                          params.phone.length !== maxPhoneLength && idx === params.phone.length - 1
                        "
                        class="_phone-icon _plus el-icon-circle-plus-outline"
                        @click="onPlus"
                      />
                      <span
                        v-show="
                          params.phone.length === maxPhoneLength || idx < params.phone.length - 1
                        "
                        class="_phone-icon el-icon-remove-outline"
                        @click="onCut(idx)"
                      />
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-form-item label="备注">
              <el-input
                v-model="params.remark"
                type="textarea"
                placeholder="请输入"
                maxlength="500"
                show-word-limit
                clearable
                :rows="5"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="_sales-item">
        <div class="_sales-header">
          <div class="_sales-title">
            <span>套餐信息</span>
          </div>
        </div>
        <div class="_sales-content">
          <el-form inline>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="套餐名称：">{{ data.name || "--" }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="套餐类型：">{{ data.typeText || "--" }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="套餐有效期：">{{ data.expireDay || "--" }}天</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="套餐金额：">{{ data.price || "0" }}元</el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>

      <div slot="footer" class="_dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { inputRequired, phoneValid } from "@/utils/rules";

const PHONE_ITEM = { name: "" };

export default {
  name: "SalesDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const checkPhone = () => {
      return {
        validator: (rule, value, callback) => {
          if (value) {
            this.$Axios
              ._get({
                url: this.$Config.apiUrl.checkCouponPhone,
                params: {
                  phone: value,
                  companyId: this.data.companyId,
                },
              })
              .then((res) => {
                if (res.data !== "") {
                  return callback(new Error(res.data));
                }
                callback();
              })
              .catch((err) => {
                callback(new Error(err.message || "手机号验证失败，请稍后再试!"));
              });
            return;
          }
          callback();
        },
        trigger: "blur",
      };
    };

    const checkPhoneRepeat = () => {
      return {
        validator: (rule, value, callback) => {
          if (value) {
            const left = this.params.phone.filter((item) => item.name === value);
            if (left.length >= 2) {
              return callback(new Error("手机号重复，请重新输入"));
            }
          }
          callback();
        },
        trigger: "blur",
      };
    };

    return {
      params: {
        phone: [JSON.parse(JSON.stringify(PHONE_ITEM))],
        remark: "",
      },
      maxPhoneLength: 16,
      rules: [inputRequired("手机号"), phoneValid(), checkPhoneRepeat(), checkPhone()],
    };
  },
  methods: {
    onClose() {
      this.$emit("input", false);
      this.$emit("clear");
      this.params = {
        phone: [JSON.parse(JSON.stringify(PHONE_ITEM))],
        remark: "",
      };

      this.$refs.elForm.clearValidate();
    },
    onConfirm() {
      this.$refs.elForm.validate((valid) => {
        if (!valid) {
          return;
        }

        this.$Axios
          ._post({
            url: this.$Config.apiUrl.addSalesCoupon,
            method: "post",
            params: {
              cardId: this.data.id,
              phones: this.params.phone.map((item) => item.name),
              remark: this.params.remark,
            },
          })
          .then(() => {
            this.$emit("success");
            this.$message.success("新增售卖成功");
            this.onClose();
          });
      });
    },
    onPlus() {
      if (this.params.phone.length === this.maxPhoneLength) {
        return;
      }
      this.params.phone.push(JSON.parse(JSON.stringify(PHONE_ITEM)));
    },
    onCut(idx) {
      this.params.phone.splice(idx, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  .el-dialog__title {
    font-weight: bold;
  }
}

/deep/ .el-dialog__body {
  padding-bottom: 0;
}

._sales-item {
  margin-bottom: 24px;
  ._sales-header {
    margin-bottom: 12px;
    ._sales-title {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

._phone-wrapper {
  display: flex;
  align-items: center;
  ._phone-icon {
    margin-left: 12px;
    color: #999999;
    font-size: 20px;
    cursor: pointer;
    &._plus {
      color: #007aff;
    }
  }
}

._label-phone {
  margin-bottom: 12px;
  ._required {
    margin-right: 2px;
    color: red;
    font-weight: bold;
  }
}

._col-item {
  margin-top: 6px;
}

._dialog-footer {
  padding: 15px 25px;
  margin: 0 -25px;
  text-align: right;
  border-top: 1px solid #ebebeb;
}

._tips {
  margin-left: 4px;
  cursor: pointer;
}
</style>
