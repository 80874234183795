<template>
  <div class="_base-info">
    <el-form>
      <div class="_base-info-item">
        <div class="_base-info-title">套餐信息</div>
        <div class="_base-info-content">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="所属商户（简称）：">
                {{ coupon.companyName || "--" }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="套餐名称：">{{ coupon.name || "--" }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="套餐类型：">{{ typeMap[coupon.type] }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="套餐有效期：">{{ format(coupon.expireDay) }}天</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="套餐金额（元）：">{{ format(coupon.price) }}元</el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="套餐说明：">{{ coupon.remark || "--" }}</el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="_base-info-item">
        <div class="_base-info-title">充电信息</div>
        <div class="_base-info-content">
          <el-row>
            <el-col :span="8">
              <el-form-item label="套餐充电次数：">
                {{ coupon.chargeNumType === 1 ? "不限次" : `${format(coupon.chargeNum)}次` }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="每日充电次数：">
                {{ format(coupon.oneDayChargeNum) }}次
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="最大时长：">
                {{ format(coupon.oneTimeChargeDurationNum) }}分钟
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="免费时长：">
                {{ format(coupon.hesitationDuration) }}分钟
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "BaseInfo",
  props: {
    coupon: {
      type: Object,
      default: () => ({}),
    },
    typeMap: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    format(value) {
      if (value === 0) {
        return value;
      }
      return value || "--";
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-form-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  .el-form-item__label {
    white-space: nowrap;
  }
  .el-form-item__content {
    position: relative;
    top: 5px;
    line-height: 1.5;
  }
}

._base-info-item {
  margin-bottom: 30px;
}

._base-info-title {
  margin-bottom: 20px;
  font-weight: bold;
}
</style>
