<template>
  <el-tabs v-model="curTab">
    <el-tab-pane v-for="item in list" :key="item.value" :label="item.label" :name="item.value" />
  </el-tabs>
</template>

<script>
export default {
  name: "TabsList",
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    curTab: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__header {
  margin: 0;
  .el-tabs__item {
    padding: 0 20px !important;
  }
}
</style>
