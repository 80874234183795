<template>
  <le-pagview :page-param="pageParams" :total.sync="total" @setData="setTableData">
    <el-table
      v-sticky="{ parent: '.el-card__body' }"
      :data="tableData"
      :highlight-current-row="true"
    >
      <el-table-column label="序号" type="index" />
      <el-table-column label="用户昵称" prop="userName" min-width="100px" />
      <el-table-column label="手机号码" prop="telephone" min-width="120px" />
      <el-table-column label="是否实名认证" prop="authenticationText" min-width="120px" />
      <el-table-column label="剩余时长（天）" prop="surplusDay" min-width="120px" />
      <el-table-column label="剩余套餐次数" prop="leftTimes" min-width="120px" />
      <el-table-column label="已使用套餐次数" prop="usedTimes" min-width="120px" />
      <el-table-column label="生效状态" prop="validText" />
      <el-table-column label="备注" prop="remark" width="150px">
        <template slot-scope="{ row }">
          <el-tooltip placement="top">
            <div slot="content" class="_remark-tip">{{ row.remark }}</div>
            <div class="_remark">{{ row.remark }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="售卖人" prop="createUserName" min-width="120px" />
      <el-table-column label="售卖日期" prop="createTime" min-width="170px" />
      <el-table-column label="操作">
        <template v-if="row.validStatus !== 'expired'" slot-scope="{ row }">
          <el-button type="text" @click="onEnableWithBan(row)">
            {{ row.enabled === 0 ? "启用" : "禁用" }}
          </el-button>
          <el-button type="text" class="_del-btn" @click="onDeleteCoupon(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </le-pagview>
</template>

<script>
const VALID_STATUS = {
  expired: "已失效",
  ban: "已禁用",
  valid: "生效中",
};

export default {
  name: "BaseInfo",
  props: {
    pageParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      total: 0,
      // 表格数据
      tableData: [],
    };
  },
  methods: {
    setTableData(data) {
      this.tableData = (data || []).map((item) => {
        const validStatus = (() => {
          const isValid = new Date(item.expireTime).getTime() > new Date().getTime();
          if (isValid === false) {
            return "expired";
          }
          if (item.enabled === 0) {
            return "ban";
          }
          return "valid";
        })();
        return {
          ...item,
          authenticationText: item.authentication === true ? "是" : "否",
          validStatus,
          validText: VALID_STATUS[validStatus],
        };
      });
    },
    // 删除
    onDeleteCoupon(row) {
      if (row.usedTimes > 0) {
        return this.$message.warning("该套餐用户已使用不能删除，建议禁用");
      }
      this.$confirm(
        `请确认是否删除【${row.telephone}】的套餐，删除后用户将无法使用，且不可恢复请谨慎操作。`,
        "删除",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }
      )
        .then(() => {
          this.$Axios
            ._get({
              url: this.$Config.apiUrl.delCouponCard,
              params: {
                id: row.mealCardSellId,
              },
            })
            .then(() => {
              this.$message.success("删除成功");
              this.$emit("refresh");
            })
            .catch(() => {
              this.$message.warning("操作失败");
            });
        })
        .catch(() => {});
    },
    // 启用/禁用
    onEnableWithBan(row) {
      const title = row.enabled === 0 ? "启用" : "禁用";
      const content =
        row.enabled === 0
          ? `请确认是否启用【${row.telephone}】的套餐，启用后用户可以继续使用。`
          : `请确认是否禁用【${row.telephone}】的套餐，禁用后用户将无法使用，请谨慎操作。`;

      this.$confirm(content, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$Axios
            ._get({
              url: this.$Config.apiUrl.banWithEnableCoupon,
              params: {
                id: row.mealCardSellId,
                isEnable: row.enabled === 0 ? 1 : 0,
              },
            })
            .then(() => {
              this.$message.success("操作成功");
              this.$emit("refresh");
            })
            .catch(() => {
              this.$message.warning("操作失败");
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
._remark {
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

._remark-tip {
  max-width: 600px;
}

._del-btn {
  color: red;
}
</style>
