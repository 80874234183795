<template>
  <div class="app-body _coupon-detail">
    <div class="_coupon-detail-header">
      <div class="_header-lefy">
        <bread-crumb></bread-crumb>
      </div>
      <div class="_header-right">
        <el-button v-if="coupon.effectuateNum === 0" type="danger" @click="onDeleteCoupon">
          删除
        </el-button>
        <el-button type="primary" style="margin-left: 10px" @click="addSales.toggle = true">
          售卖
        </el-button>
      </div>
    </div>
    <el-card class="el-main">
      <div class="_tabs-wrapper">
        <tabs-list v-model="currentTab" :list="tabList" />
      </div>
      <div class="_tabs-content">
        <el-scrollbar style="height: 100%">
          <div class="_tans-comp">
            <components
              :is="compMap[currentTab]"
              :coupon="coupon"
              :page-params="pageParams"
              :type-map="typeMap"
              @refresh="onSalesSuccess"
            />
          </div>
        </el-scrollbar>
      </div>
    </el-card>

    <sales-dialog v-model="addSales.toggle" :data="coupon" @success="onSalesSuccess" />
  </div>
</template>

<script>
import axios from "axios";

import BaseInfo from "./components/BaseInfo.vue";
import CouponInfo from "./components/CouponInfo.vue";
import SalesDialog from "./components/SalesDialog.vue";
import TabsList from "./components/TabList.vue";

const TAB_LIST = [
  {
    label: "套餐信息",
    value: "baseInfo",
  },
  {
    label: "售卖信息",
    value: "couponInfo",
  },
];

const COMP = {
  baseInfo: BaseInfo,
  couponInfo: CouponInfo,
};

export default {
  name: "CouponDetail",
  components: {
    TabsList,
    SalesDialog,
  },
  data() {
    return {
      currentTab: "baseInfo",
      coupon: {},
      couponTypeList: [],
      pageParams: {
        url: this.$Config.apiUrl.getCardSalesInfo,
        method: "post",
        params: {
          cardId: this.$route.params.id,
        },
        freshCtrl: 0,
      },
      addSales: {
        toggle: false,
      },
    };
  },
  computed: {
    tabList() {
      return TAB_LIST;
    },
    compMap() {
      return COMP;
    },
    typeMap() {
      return this.couponTypeList.reduce((map, item) => {
        map[item.id] = item.name;
        return map;
      }, {});
    },
  },
  created() {
    axios.all([this.getCouponTypeList(), this.getDetail()]);
  },
  methods: {
    // 获取套餐卡类型
    getCouponTypeList() {
      return new Promise((resolve) => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.getCouponTypeList,
          })
          .then((res) => {
            this.couponTypeList = res.data || [];
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    // 详情
    getDetail() {
      return new Promise((resolve) => {
        const id = this.$route.params.id;
        if (id === void 0) {
          this.$message.error("id不能为空");
          return resolve();
        }
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.getCoupontDetail,
            params: {
              cardId: this.$route.params.id,
            },
          })
          .then((res) => {
            this.coupon = res.data || {};
            this.coupon = {
              ...this.coupon,
              typeText: this.typeMap[this.coupon.type],
              price: (this.coupon.price || 0) / 100,
            };
            resolve();
          })
          .catch(() => {
            this.$message.error("获取详情失败");
            resolve();
          });
      });
    },
    onSalesSuccess() {
      if (this.currentTab === "baseInfo") {
        return this.getDetail();
      }
      this.pageParams.freshCtrl++;
    },
    onDeleteCoupon() {
      this.$confirm("删除后不可恢复，请确认是否删除该套餐？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$Axios
            ._get({
              url: this.$Config.apiUrl.deleteCoupon,
              params: {
                cardId: this.coupon.id,
              },
            })
            .then(() => {
              this.$message.success("删除套餐成功");
              this.$router.push("/coupon/coupon-list");
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
._coupon-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._tabs-content {
  height: calc(100% - 40px);
}

._tans-comp {
  padding: 20px;
  box-sizing: border-box;
}

/deep/ .el-card {
  padding: 0;
  .el-card__body {
    padding: 0;
  }
}
</style>
